import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/database"
import "firebase/messaging";
import "firebase/storage"
import axios from "axios";

const firebaseConfig =
{
    apiKey: "AIzaSyDXvbr8nQKNcU83RcWurNUKMsnNJSKICKk",
    authDomain: "elim-cef62.firebaseapp.com",
    projectId: "elim-cef62",
    storageBucket: "elim-cef62.appspot.com",
    messagingSenderId: "115330595622",
    appId: "1:115330595622:web:c270a645601b8975565fe7"
};
const config =
{
    accid: "opv",
    client: axios.create({baseURL: 'https://api-elim.binext.com.br'})//'http://localhost:8080' })
};

const firebaseApp = firebase.initializeApp(firebaseConfig);
/*you can enable persistence to allow the user to see previous data when he's offline but it will make your queries very slow which leads
to bad user experience so I suggest you implement your own offline support by caching the data and retrieving them when the user is offline*/
//firebaseApp.firestore().enablePersistence();
const db = firebaseApp.firestore();
const db2 = firebaseApp.database();
const auth = firebaseApp.auth();
// const provider = new firebase.auth.GoogleAuthProvider();
const createTimestamp = firebase.firestore.FieldValue.serverTimestamp;
const createTimestamp2 = firebase.database.ServerValue.TIMESTAMP;
const messaging = "serviceWorker" in navigator && "PushManager" in window ?  firebase.messaging() : null;
const fieldIncrement = firebase.firestore.FieldValue.increment;
const arrayUnion = firebase.firestore.FieldValue.arrayUnion;
const storage = firebase.storage().ref("images");
const audioStorage = firebase.storage().ref("audios");
const videoStorage = firebase.storage().ref("videos");

//db.disableNetwork();

export /*{loadFirebase, provider ,*/ {auth, createTimestamp, messaging, fieldIncrement, arrayUnion, storage, audioStorage, videoStorage, db2, createTimestamp2, config};
export default db;
