import {memo, useState} from 'react';
import { Button } from '@material-ui/core';
import { Input } from '@material-ui/core';
import { auth, provider, config } from './firebase';
import './Login.css'

function Login() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const signIn = () => {
        // auth.signInWithRedirect(provider).catch(e => alert(e.message))
        setError("");
        config.client.post('/authV2/firebaseTokenLogin',
        {
            accid: config.accid,
            appid: "attendance",
            apps: "admin,attendance,access",
            colid: 'documents',
            login: email,
            password: password,
            mapItems:
            {
                referencePath: "opv/attendance/grids/bwldaqwvIRva77PS61wd"
            }
        }).then((response) =>
        {
            let result = response.data;
            if(result.status)
            {
                auth.signInWithCustomToken(result.data.token);
                // delete result.data.token;
                localStorage.setItem("user", JSON.stringify(result.data));
            }
            else
            {
                setError(result.error ? result.error : "Login ou senha inválidos!");
            }
        });
    };

    const handleEmailChange = (e) =>
    {
        setEmail(e.target.value);
    };

    const handlePasswordChange = (e) =>
    {
        setPassword(e.target.value);
    };

    return (
        <div className="login">
            <div className="login__container">
                <img
                    src="/logo.png"
                    alt=""
                />
                {/* <div className="login__text">
                    <h1>Entre para Conversar</h1>
                </div> */}

                <Input label="E-mail" type="text" name="email" placeholder="E-mail" value={email} onChange={handleEmailChange}/>
                <Input label="Senha" type="password" name="password" placeholder="Senha" value={password} onChange={handlePasswordChange}/>
                <span className={"error"}>{error}</span>
                <Button onClick={signIn}>
                    Entrar
                </Button>
            </div>
        </div>
    )
}

export default memo(Login)