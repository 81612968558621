import { useEffect, useState, memo, useRef } from 'react';
import SidebarChat from './SidebarChat';
import { Avatar, IconButton } from '@material-ui/core';
import { Message, PeopleAlt, Home, ExitToApp as LogOut, SearchOutlined, GetAppRounded, Add } from '@material-ui/icons';
import db, { auth, config, createTimestamp } from "./firebase";
import { useStateValue } from './StateProvider';
import { NavLink, Route, useHistory, Switch } from 'react-router-dom';
import algoliasearch from "algoliasearch";
import './Sidebar.css';
import audio from './notification.mp3'

// const index = algoliasearch("HGSCPXF5HH", "5dcbf917421397576df267019b9b4c87").initIndex('whatsappy-app');

function Sidebar({ chats, pwa, rooms, fetchRooms, users, fetchUsers }) {
    const [searchList, setSearchList] = useState(null);
    const [searchInput, setSearchInput] = useState("");
    const [menu, setMenu] = useState(3);
    const [mounted, setMounted] = useState(false);
    const [{ user, page, pathID }] = useStateValue();
    let history = useHistory();
    const notification = new Audio(audio);
    const prevUnreadMessages = useRef((() => {
        const data = {};
        chats.forEach(cur => cur.unreadMessages || cur.unreadMessages === 0 ? data[cur.id] = cur.unreadMessages : null);
        return data;
    })());

    var Nav;
    if (page.width > 760) {
        Nav = (props) =>
            <div className={`${props.classSelected ? "sidebar__menu--selected" : ""}`} onClick={props.click}>
                {props.children}
            </div>
    } else {
        Nav = NavLink;
    }

    async function search(e) {
        if (e) {
            document.querySelector(".sidebar__search input").blur();
            e.preventDefault();
        }
        if (page.width <= 760) {
            history.push("/search?" + searchInput);
        };
        setSearchList(null);
        if (menu !== 4) {
            setMenu(4)
        };
        // const result = (await index.search(searchInput)).hits.map(cur => cur.objectID !== user.uid ? {
        //     ...cur,
        //     id: cur.photoURL ? cur.objectID > user.uid ? cur.objectID + user.uid : user.uid + cur.objectID : cur.objectID,
        //     userID: cur.photoURL ? cur.objectID : null
        // } : null);
        // //console.log(result);
        // setSearchList(result);
        const user = JSON.parse(localStorage.getItem('user'));
        var appid;
        if(user.appid == 'attendance' || user.appid == 'admin')
        {
            appid = 'access';
        }
        else
        {
            appid = 'attendance';
        }
        let searchRef = await db.collection(config.accid + '/' + appid + '/documents').where("search", "array-contains", searchInput.toLowerCase()).get();
        let list = []
        searchRef.forEach((item) =>
        {
            list.push(item.data());
        })
        setSearchList(list);
        // let appid = "access";
        // const response = await config.client.post('/documentV2/collection',
        // {
        //     accid: config.accid,
        //     appid: appid,
        //     colid: 'documents',
        //     where:
        //     [
        //         {
        //             field: 'search',
        //             operator: 'array-contains',
        //             value: searchInput.toLowerCase()
        //         }
        //     ]
        // });
        // console.log(response.data);
    }

    const createChat = () => {
        const roomName = prompt("Digite o nome do grupo");
        if (roomName) {
            db.collection(config.accid + "/rooms/documents").add({
                name: roomName,
                timestamp: createTimestamp(),
                lastMessage: "",
            });
        };
    };

    useEffect(() => {
        const data = {};
        chats.forEach(cur => {
            if (cur.unreadMessages || cur.unreadMessages === 0) {
                if ((cur.unreadMessages > prevUnreadMessages.current[cur.id] || !prevUnreadMessages.current[cur.id] && prevUnreadMessages.current[cur.id] !== 0) && pathID !== cur.id) {
                    notification.play();
                };
                data[cur.id] = cur.unreadMessages;
            };
        });
        prevUnreadMessages.current = data;
    }, [chats, pathID]);

    useEffect(() => {
        if (page.width <= 760 && chats && !mounted) {
            setMounted(true);
            setTimeout(() => {
                document.querySelector('.sidebar').classList.add('side');
            }, 10);
        };
    }, [chats, mounted]);

    return (
        <div className="sidebar" style={{
            minHeight: page.width <= 760 ? page.height : "auto"
        }}>
            <div className="sidebar__header hidden">
                <div className="sidebar__header--left">
                    <Avatar src={user.photo?._url } />
                    <h4>{user?.displayName} </h4>
                </div>
                <div className="sidebar__header--right">
                    <IconButton onClick={() => {
                        if (pwa) {
                            console.log("prompting the pwa event")
                            pwa.prompt()
                        } else {
                            console.log("pwa event is undefined")
                        }
                    }} >
                        <GetAppRounded />
                    </IconButton>
                    <IconButton onClick={ () => {
                        auth.signOut();
                        const dbuser = JSON.parse(localStorage.getItem("user"));
                        localStorage.removeItem("user");
                        db.doc(config.accid + "/" + dbuser.appid + "/documents/" + user.uid).set({ state: "offline" }, { merge: true });
                        history.replace("/");
                    }} >
                        <LogOut />
                    </IconButton>

                </div>
            </div>

            <div className="sidebar__search hidden">
                <form className="sidebar__search--container">
                    <SearchOutlined />
                    <input
                        value={searchInput}
                        onChange={(e) => setSearchInput(e.target.value)}
                        placeholder="Procurar usuários"
                        type="text"
                    />
                    <button style={{ display: "none" }} type="submit" onClick={search}></button>
                </form>
            </div>

            <div className="sidebar__menu">
                {/* Não usado nessa versão */}
                {/* <Nav
                    classSelected={menu === 2 ? true : false}
                    to="/rooms"
                    click={() => setMenu(2)}
                    activeClassName="sidebar__menu--selected"
                >
                    <div className="sidebar__menu--rooms">
                        <Message />
                        <div className="sidebar__menu--line"></div>
                    </div>
                </Nav> */}
                <Nav
                    classSelected={menu === 3 ? true : false}
                    to="/users"
                    click={() => setMenu(3)}
                    activeClassName="sidebar__menu--selected"
                >
                    <div className="sidebar__menu--users">
                        <PeopleAlt />
                        <div className="sidebar__menu--line"></div>
                    </div>
                </Nav>
                <Nav
                    classSelected={menu === 1 ? true : false}
                    to="/chats"
                    click={() => setMenu(1)}
                    activeClassName="sidebar__menu--selected"
                >
                    <div className="sidebar__menu--home">
                        <Home />
                        <div className="sidebar__menu--line"></div>
                    </div>
                </Nav>
            </div>

            {page.width <= 760 ?
                <>
                    <Switch>
                        <Route path={"/users"}>
                            <SidebarChat key="users" fetchList={fetchUsers} dataList={users} title="contatos" path="/users" />
                        </Route>
                        <Route path="/rooms">
                            <SidebarChat key="rooms" fetchList={fetchRooms} dataList={rooms} title="grupos" path="/rooms" />
                        </Route>
                        <Route path="/search">
                            <SidebarChat key="search" dataList={searchList} title="resultados" path="/search" />
                        </Route>
                        <Route path="/chats">
                            <SidebarChat key="chats" dataList={chats} title="conversas" path="/chats" />
                        </Route>
                        {/* <Route path="%PUBLIC_URL%/chats" element={ <SidebarChat key="chats" dataList={chats} title="conversas" path="/chats" />}/> */}
                    </Switch>
                </>
                :
                menu === 1 ?
                    <SidebarChat key="chats" dataList={chats} title="conversas" />
                    : menu === 2 ?
                        <SidebarChat key="rooms" fetchList={fetchRooms} dataList={rooms} title="grupos" />
                        : menu === 3 ?
                            <SidebarChat key="users" fetchList={fetchUsers} dataList={users} title="contatos" />
                            : menu === 4 ?
                                <SidebarChat key="search" dataList={searchList} title="resultados" />
                                : null
            }
            {/* Não usado nessa versão */}
            {/* <div className="sidebar__chat--addRoom" onClick={createChat}>
                <IconButton >
                    <Add />
                </IconButton>
            </div> */}
        </div>
    );
};

export default memo(Sidebar);
